import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import {
  makeStyles,
  styled,
} from '@material-ui/core/styles'
import { spacing } from '@material-ui/system'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import globalStyles from 'src/styles/globalStyles'

import {
  Button as MuiButton,
  Container as MuiContainer,
  Hidden,
  Typography,
  Grid as MuiGrid,
  Box,
  AppBar,
  Toolbar,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ButtonGroup,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Tabs,
  Tab
} from '@material-ui/core'

import Layout from 'src/components/layout'
import Assessment from 'src/components/aboutUs/assessment'
import { SectionHeader } from 'src/components/headers'

import 'swiper/swiper.scss'
import 'src/styles/swiper.scss'
import SwiperCore, {
  Autoplay as SwiperAutoplay,
  Navigation as SwiperNavigation,
  Pagination as SwiperPagination
} from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import { parseAlt } from 'src/helpers'

SwiperCore.use([SwiperAutoplay, SwiperNavigation, SwiperPagination])

const Button = styled(MuiButton)(spacing)
const Container = styled(MuiContainer)(spacing)
const Grid = styled(MuiGrid)(spacing)

const useStyles = makeStyles(theme => ({
  ...globalStyles,
  pic: {
    overflow: 'hidden',
  },
  foundation: {
    backgroundColor: '#303657',
    color: 'white'
  },
  org: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '150px',
    paddingBottom: '30px',
    textAlign: 'center'
  },
  separator: {
    width: '50px',
    height: '3px',
    backgroundColor: '#3871c2',
    margin: 'auto'
  }
}))

const CSR = ({ data, pageContext }) => {
  const Csr = data.contentfulCsr
  const classes = useStyles()
  const theme = useTheme()
  const bptMd = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Layout pageContext={pageContext}>
      <Container pt={8} pb={8}>
        <Grid className={classes.spaceBetween} container direction={bptMd ? 'row' : 'column-reverse'} spacing={bptMd ? 8 : 1}>
          <Grid item xs={12} md={6}>
            <Box borderRadius={4} className={classes.pic}>
              <GatsbyImage image={getImage(Csr.ourMissionPicture)} alt={parseAlt(Csr.ourMissionPicture, pageContext)} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <SectionHeader>{Csr.ourMission}</SectionHeader>
            <Box className={classes.justify}>
              {Csr.ourMissionParagraph1 ? (
                documentToReactComponents(JSON.parse(Csr.ourMissionParagraph1.raw))
              ) : ''}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.foundation}>
        <Container py={6}>
          <Grid className={classes.spaceBetween} alignItems="center" container spacing={bptMd ? 8 : 1}>
            <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
              <GatsbyImage image={getImage(Csr.foundationLogo)} alt={parseAlt(Csr.foundationLogo, pageContext)} />
              <Box className={classes.justify} pt={3}>
                {Csr.foundationParagraph1 ? (
                  documentToReactComponents(JSON.parse(Csr.foundationParagraph1.raw))
                ) : ''}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box borderRadius={4} className={classes.pic}>
                <GatsbyImage image={getImage(Csr.foundationPicture)} alt={parseAlt(Csr.foundationPicture, pageContext)} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container pt={8} pb={6}>
        <Grid className={classes.spaceBetween} container spacing={bptMd ? 8 : 1}>
          <Grid item xs={12} md={6}>
            <SectionHeader>{Csr.initiatives}</SectionHeader>
            <Box className={classes.listWrapper}>
              {Csr.ourMissionParagraph1 ? (
                documentToReactComponents(JSON.parse(Csr.initiativesParagraph1.raw))
              ) : ''}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box borderRadius={4} className={classes.pic}>
              <GatsbyImage image={getImage(Csr.initiativesPicture1)} alt={parseAlt(Csr.initiativesPicture1, pageContext)} />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container pb={8}>
        <SectionHeader h3>{Csr.organizations}</SectionHeader>
        <Swiper className={classes.swiperBullets} slidesPerView={bptMd ? 4 : 2} spaceBetween={30} pagination={{ clickable: true }} autoplay={{ delay: 1500 }}>
          {Csr.organizationsList.map(org => (
            <SwiperSlide key={org.id}>
              <div className={classes.org}>
                <Box width="50%">
                  <GatsbyImage image={getImage(org)} alt={parseAlt(org, pageContext)} />
                </Box>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
      <Container pb={8}>
        <Grid className={classes.spaceBetween} container direction={bptMd ? 'row' : 'column-reverse'} spacing={bptMd ? 8 : 1}>
          <Grid item xs={12} md={6}>
            <Box borderRadius={4} className={classes.pic}>
              <GatsbyImage image={getImage(Csr.awardsPicture1)} alt={parseAlt(Csr.awardsPicture1, pageContext)} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <SectionHeader>{Csr.awards}</SectionHeader>
            <Box>
              {Csr.awardsParagraph1 ? (
                documentToReactComponents(JSON.parse(Csr.awardsParagraph1.raw))
              ) : ''}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container pt={2} pb={8}>
        <Box className={classes.separator}></Box>
        <Box px={bptMd ? 16 : 0} pt={2}>
          {Csr.disability ? (
            documentToReactComponents(JSON.parse(Csr.disability.raw))
          ) : ''}
        </Box>
        <Swiper className={classes.swiperBullets} slidesPerView={bptMd ? 3 : 2} spaceBetween={30} pagination={{ clickable: true }} autoplay={{ delay: 1500 }}>
          {Csr.disabilityList.map(logo => (
            <SwiperSlide key={logo.id}>
              <div className={classes.org}>
                <Box width="50%">
                  <GatsbyImage image={getImage(logo)} alt={parseAlt(logo, pageContext)} />
                </Box>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </Layout>
  )
}

export default CSR

export const pageQuery = graphql`
  query CSR($id: String!) {
    contentfulCsr(id: { eq: $id }) {
      name
      ourMission
      ourMissionParagraph1 {
        raw
      }
      ourMissionPicture {
        description
        gatsbyImageData(layout: CONSTRAINED)
      }
      foundation
      foundationParagraph1 {
        raw
      }
      foundationPicture {
        description
        gatsbyImageData(layout: CONSTRAINED)
      }
      foundationLogo {
        description
        gatsbyImageData(layout: CONSTRAINED, width: 300)
      }
      initiatives
      initiativesParagraph1 {
        raw
      }
      initiativesPicture1 {
        description
        gatsbyImageData(layout: CONSTRAINED)
      }
      organizations
      organizationsList {
        id
        description
        gatsbyImageData(layout: CONSTRAINED)
      }
      awards
      awardsParagraph1 {
        raw
      }
      awardsPicture1 {
        description
        gatsbyImageData(layout: CONSTRAINED)
      }
      disability {
        raw
      }
      disabilityList {
        id
        description
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
